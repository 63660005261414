import React, { useState } from "react";
import { Redirect, Link } from "react-router-dom";

import DashboardComponents from "./dashboard";

import Im from "traec/immutable";
import Moment from "moment";
import { connect } from "react-redux";
import Traec from "traec";

import * as dashboardUtils from "AppSrc/dashboards/utils";
import * as reporting from "AppSrc/dashboards/reportingPeriods";
import { ColorScale } from "AppSrc/dashboards/colorScale";

import { loading } from "traec-react/utils/entities";
import { DateRangePicker } from "traec-react/utils/dateRangePicker";
import { BSBtnDropdown, BSModal } from "traec-react/utils/bootstrap/";
import { parseReportingPeriods } from "AppSrc/dashboards";
import Swal from "sweetalert2";

import { alertSuccess } from "traec-react/utils/sweetalert";
import { getCompanyPermissions, CompanyPermission } from "traec/utils/permissions/company";
import { ErrorBoundary } from "traec-react/errors";
import { CompanyWarningMessage } from "./warning";

import { setAndShowModal } from "AppSrc/utils/modal";
import { Tooltip } from "react-tippy";
import Octicon from "react-octicon";

import {
  ReportCount,
  ReportsApproved,
  ReportStatusDoughnut,
  ReportSubmittedLine,
} from "storybook-dashboard/dashboard/health/widgets";
import IndicatorsDoughnut from "storybook-dashboard/dashboard/health/widgets/indicatorsDoughnut";
import { ExportAccountDataModal } from "storybook-dashboard/dashboard/export/company/account"
import { Modal, useModal } from "storybook-dashboard/components/modal"
import { fetchIndicatorExport } from "storybook-dashboard/dashboard/export/company/fetch"



const getMomentFromLocalStorageOrDefault = (key, defaultValue = null) => {
  let date = localStorage.getItem(key);
  console.log(`Reading localStorage key: ${key}`, date);
  if (date) {
    date = Moment(date);
    return date.isValid() ? date : defaultValue;
  }
  return defaultValue;
};

const getZipPackage = ({ companyId, metricIds, pending, setPending }) => {
  let fetch = new Traec.Fetch("company_dispatch", "post", { companyId });

  if (pending) {
    return null;
  }
  setPending(true);

  fetch.updateFetchParams({
    body: {
      type: "ZIP_ALL_UPLOADS",
      payload: {
        baseMetricIds: metricIds.split(",")
      }
    }
  });
  fetch
    .rawFetch({ updateBody: true })
    .then(response => {
      setPending(false);
      return response.blob();
    })
    .then(data => {
      let blobUrl = window.URL.createObjectURL(data);
      const link = document.createElement("a");
      link.style.display = "none";
      link.href = blobUrl;
      link.download = "file_package.zip";
      document.body.appendChild(link);
      link.click();
    });
};

const getFormJoin = ({ companyId, formName, pending, setPending }) => {
  let fetch = new Traec.Fetch("company_dispatch", "post", { companyId });

  if (pending) {
    return null;
  }
  setPending(true);

  fetch.updateFetchParams({
    body: {
      type: "JOIN_FORMS",
      payload: {
        formName: formName
      }
    }
  });
  fetch
    .rawFetch({ updateBody: true })
    .then(response => {
      setPending(false);
      return response.blob();
    })
    .then(data => {
      let blobUrl = window.URL.createObjectURL(data);
      const link = document.createElement("a");
      link.style.display = "none";
      link.href = blobUrl;
      link.download = "form.csv";
      document.body.appendChild(link);
      link.click();
    });
};

export const comapnyDispatchToDownload = ({
  companyId,
  pending,
  setPending,
  actionName,
  fileName = "unkown",
  payload = {}
}) => {
  let fetch = new Traec.Fetch("company_dispatch", "post", { companyId });

  if (pending) {
    return null;
  }
  setPending(true);

  fetch.updateFetchParams({
    body: {
      type: actionName,
      payload
    }
  });
  fetch
    .rawFetch({ updateBody: true })
    .then(response => {
      setPending(false);
      return response.blob();
    })
    .then(data => {
      let blobUrl = window.URL.createObjectURL(data);
      const link = document.createElement("a");
      link.style.display = "none";
      link.href = blobUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
    });
};

function CompanyExportZipForm({ companyId }) {
  let [pending, setPending] = useState(false);
  let [metricIds, setMetricIds] = useState("");

  return (
    <ErrorBoundary>
      <div className="form-group">
        <label htmlFor="metricIds">Zip package files for Metric IDs:</label>
        <input
          className="form-control"
          id="metricIds"
          aria-describedby="inputHelp"
          placeholder="Enter metric ids (comma separated)"
          onChange={e => setMetricIds(e.target.value)}
        />
        <small id="inputHelp" className="form-text text-muted">
          Comma-separated list of metric ids to export files for.
          <br />
          <b>
            Note: this may take a while depending on the size of your reporting tree and the number of files. Please be
            patient.
          </b>
        </small>
      </div>
      <button
        className="btn btn-sm btn-primary pt-0 pb-0 ml-1 mr-1 mb-2"
        onClick={e => {
          e.preventDefault();
          getZipPackage({ companyId, metricIds, pending, setPending });
        }}
      >
        {pending ? <div className="spinner-sm" /> : "Get zip package"}
      </button>
    </ErrorBoundary>
  );
}

function CompanyFormJoin({ companyId }) {
  let [pending, setPending] = useState(false);
  let [formName, setFormName] = useState("");

  return (
    <ErrorBoundary>
      <div className="form-group">
        <label htmlFor="inputs">Join forms by name:</label>
        <input
          className="form-control"
          id="metricIds"
          aria-describedby="inputHelp"
          placeholder="Enter form name"
          onChange={e => setFormName(e.target.value)}
        />
        <small id="inputHelp" className="form-text text-muted">
          Name of form to join data for
          <br />
          <b>
            Note: this may take a while depending on the size of your reporting tree and the number of forms. Please be
            patient.
          </b>
        </small>
      </div>
      <button
        className="btn btn-sm btn-primary pt-0 pb-0 ml-1 mr-1 mb-2"
        onClick={e => {
          e.preventDefault();
          getFormJoin({ companyId, formName, pending, setPending });
        }}
      >
        {pending ? <div className="spinner-sm" /> : "Get form data"}
      </button>
    </ErrorBoundary>
  );
}

function MetricIDForm({ companyId, selectedCompanyReportingPeriods }) {
  let [value, setValue] = useState("");
  let [pending, setPending] = useState(false);
  let [forSelectedDates, setForSelectedDates] = useState(false);

  let periods = Traec.Im.fromJS(selectedCompanyReportingPeriods);
  let fromDate = periods?.first()?.get("startDate");
  let toDate = periods?.last()?.get("endDate");

  return (
    <React.Fragment>
      <input
        className={"form-control"}
        placeholder={"Enter base-metric ID to get values for (optional)"}
        value={value}
        onChange={e => setValue(e.target.value)}
      />
      <div className={`form-check mt-2`}>
        <input
          className="form-check-input"
          id={"forSelectedCheck"}
          type="checkbox"
          checked={forSelectedDates}
          onChange={() => setForSelectedDates(!forSelectedDates)}
        />
        <label className="form-check-label" htmlFor={"forSelectedCheck"}>
          For selected reporting periods only
        </label>
      </div>

      <button
        className="btn btn-sm btn-primary float-right m-2"
        onClick={e => {
          e.preventDefault();
          comapnyDispatchToDownload({
            companyId,
            pending,
            setPending,
            actionName: "INPUTS_TO_CSV",
            fileName: "metric_inputs.csv",
            payload: {
              baseMetricId: value,
              fromDate: forSelectedDates ? fromDate : null,
              toDate: forSelectedDates ? toDate : new Moment("2030-01-01").startOf("day")
            }
          });
        }}
      >
        {pending ? <div className="spinner-border spinner-border-sm" /> : "Submit"}
      </button>
      <div style={{ clear: "both" }} />
    </React.Fragment>
  );
}

const showInputsToCSV = (e, props) => {
  e.preventDefault();

  let modalId = "CompanyDashboardModal001";
  setAndShowModal(modalId, {
    title: "Metric values to",
    body: <MetricIDForm {...props} />
  });
};

function CompanyDateRangePicker({ company, onChange, fromDate, toDate }) {
  return (
    <React.Fragment>
      <div className="row mt-2">
        <div className="col-sm-12">
          <b className="float-left" style={{ fontSize: "1.15rem" }}>
            {company.get("name")}
          </b>
          <DateRangePicker onChange={onChange} fromDateValue={fromDate.toDate()} toDateValue={toDate.toDate()} />
          <div style={{ clear: "both" }} />
        </div>
      </div>
    </React.Fragment>
  );
}

function CompanyColorScale({ show }) {
  if (!show) {
    return null;
  }
  return (
    <div className="row">
      <div className="col-sm-12">
        <ColorScale />
      </div>
    </div>
  );
}

function IndicatorWarning({ indicators }) {
  if (indicators && indicators.size == 0) {
    return (
      <div className="alert alert-warning">
        <strong>No Indicators setup</strong>
        <br />
        This dashboard does not have any indicators setup yet. You will likely see minimal or no content on the
        dashboard. Request an administrator of this to setup some indicators to display the dashboard data.
      </div>
    );
  }
  return null;
}

const exportDropdownLinks = (props) => {
  let { companyId, userPermissions, modalId, showColorScale, setState, setModal} = props;
  let isAdmin = userPermissions?.get("is_admin");

  let links = [
    { 
      name: "Export Indicators to Excel", 
      onClick: () => fetchIndicatorExport({companyId, period: "year", cumulative: false, fileName: "indicators.csv"})
    },
    {
      name: "Export Cumulative Indicators to Excel",
      onClick: () => fetchIndicatorExport({companyId, period: "year", cumulative: true, fileName: "indicators_cumulative.csv"})
    },
    {},
    {
      name: "Export All Account Data",
      onClick: () => setModal(<ExportAccountDataModal {...{ companyId }} />),
    }
  ];
  if (isAdmin) {
    links.push(
      {},
      /* This option will be used to redirect the user to a page where they can customize report data */
      { name: "Export to PDF", onClick: e => setState({ rssbReport: true }) },
      {},
      {
        name: "Export files to zip package",
        onClick: e => {
          setState({ modalTitle: "Export files to zip" });
          $(`#${modalId}`).modal("show");
        }
      },
      {
        name: "Export input values to CSV",
        onClick: e => showInputsToCSV(e, props)
      },
      {
        name: "Export forms",
        onClick: e => {
          setState({ modalTitle: "Export forms" });
          $(`#${modalId}`).modal("show");
        }
      },
      {
        name: "Export report meta-data as CSV",
        onClick: e => {
          comapnyDispatchToDownload({
            companyId,
            setPending: i => {
              console.log("Setting pending to:", i);
            },
            actionName: "REPORT_META_TO_CSV",
            fileName: `${companyId.substring(0, 8)}_REPORT_META.csv`
          });
        }
      },
      {
        name: "Export project members",
        onClick: e => {
          comapnyDispatchToDownload({
            companyId,
            setPending: i => {
              console.log("Setting pending to:", i);
            },
            actionName: "PROJECT_MEMBERS_CSV",
            fileName: "project_members.csv"
          });
        }
      },
      {
        name: "Export report statuses",
        onClick: e => {
          comapnyDispatchToDownload({
            companyId,
            setPending: i => {
              console.log("Setting pending to:", i);
            },
            actionName: "REPORT_STATUS_CSV",
            fileName: "report_status.csv"
          });
        }
      },
      {},
      {
        name: `${showColorScale ? "Hide" : "Show"} colour scale`,
        onClick: e => setState({ showColorScale: !showColorScale })
      }
    );
  }

  return links;
}

function ExportDropdown(props) {
  let { setModal } = useModal()
  return (
    <BSBtnDropdown 
      header="Export" 
      links={exportDropdownLinks({...props, setModal})} 
    />
  )
}

class CompanyHome extends React.Component {
  constructor(props) {
    super(props);

    let { _companyId: companyId } = props.match.params;

    let fromDate = getMomentFromLocalStorageOrDefault(`fromDate:${companyId}`, new Moment().subtract(12, "months"));
    let toDate = getMomentFromLocalStorageOrDefault(`toDate:${companyId}`, new Moment().endOf("year"));

    this.state = {
      fetchedReportingPeriods: false,
      fetchedCompanyTargets: false,
      parsedReportingPeriods: false,
      fetchingAndParsing: false,
      iconActivated: false,
      detailedIconChart: {},
      indicatorChart: null,
      iconData: false,
      indicatorData: null,
      rssbReport: false,
      selectedSustainabilityIssue: null,
      selectedIndicators: Im.List(),
      cumulation: "current",
      iconColors: Im.Map({
        "Air Quality": "grey",
        Awards: "grey",
        Biodiversity: "grey",
        Carbon: "grey",
        Community: "grey",
        "Environmental Management": "grey",
        Ethics: "grey",
        "Health & Safety": "grey",
        Procurement: "grey",
        Materials: "grey",
        Waste: "grey",
        Water: "grey",
        Employees: "grey"
      }),

      fromDate: fromDate,
      toDate: toDate,

      // Report filters
      reportFilters: Traec.Im.Map(),
      filtersApplied: true
    };

    // Required data
    this.requiredFetches = [
      new Traec.Fetch("company", "read"),
      new Traec.Fetch("company_permission", "list"),
      new Traec.Fetch("company", "list"),
      new Traec.Fetch("company_target", "list", { companyId }),
      new Traec.Fetch("company_indicator", "list")
    ];

    // Bindings for fetching
    this.shouldComponentReFetch = reporting.shouldComponentReFetch.bind(this);
    this.setDateRangeValue = reporting.setDateRangeValue.bind(this);

    // action bindings
    this.onIconClick = dashboardUtils.onIconClick.bind(this);
    this.onIndicatorClick = dashboardUtils.onIndicatorClick.bind(this);
    this.getIndicatorColors = dashboardUtils.getIndicatorColors.bind(this);
    this.generateIndicatorChart = dashboardUtils.generateIndicatorChart.bind(this);
    this.refresh_cache = this.refresh_cache.bind(this);
    this.downloadExcel = this.downloadExcel.bind(this);
    this.setSelectedSustainabilityIssue = this.setSelectedSustainabilityIssue.bind(this);
    this.setSelectedIndicator = this.setSelectedIndicator.bind(this);
  }

  /**********************
   COMPONENT METHODS
   **********************/

  componentDidMount() {
    Traec.fetchRequiredFor(this);

    // Fetch the summary data for this company
    let { companyReportingPeriods, companyTargets } = this.props;
    this.fetchAndParse(companyReportingPeriods, companyTargets, false, "company");
  }

  componentDidUpdate(prevProps) {
    Traec.fetchRequiredFor(this);
    // Check if we should re-parse the project reporting data
    let { companyReportingPeriods, companyId, companyTargets } = this.props;

    // Reparse if the company targets have changed
    if (companyTargets && !companyTargets.equals(prevProps.companyTargets)) {
      this.setState({ parsedReportingPeriods: false });
    }

    let reParseData = reporting.shouldDashboardReparse(
      prevProps.companyId,
      prevProps.companyReportingPeriods,
      companyId,
      companyReportingPeriods,
      prevProps,
      this.props
    );

    this.fetchAndParse(companyReportingPeriods, companyTargets, reParseData, "company");
  }

  /**********************
   ACTIONS
   **********************/

  refresh_cache(e, include_children = false) {
    e.preventDefault();
    let { companyId } = this.props;
    //let { fromDate, toDate } = this.state;
    let fetch = new Traec.Fetch("company_dispatch", "post", { companyId });
    let dispatchType = include_children ? "RECALC_ALL_REF_CACHES" : "RECALC_COMPANY_CACHE";
    fetch.updateFetchParams({
      body: { type: dispatchType, payload: { from_date: this.state.fromDate, to_date: this.state.toDate } },
      postSuccessHook: () => {
        alertSuccess({
          text: `Company cache is refreshing.  Click OK to reload the site.`,
          onConfirm: () => {
            //location.reload();
          }
        });
      }
    });

    alertSuccess({
      iconType: "warning",
      title: "Cache recalculation",
      text: `This company cache will now be updated.  This may take a few minutes.  Upon completion you will be notified and prompted to refresh your browser`,
      onConfirm: () => {
        fetch.dispatch();
      }
    });
  }

  fetchAndParse(reportingPeriods, targets, reParseData, reportType = "project") {
    //
    if (reportingPeriods && (!this.state.parsedReportingPeriods || reParseData)) {
      //Parsing for Icon Charts
      let { isCumulative } = this.props;
      console.log("Re-parsing COMPANY data", this.state, isCumulative);
      let data = parseReportingPeriods(
        reportingPeriods,
        targets,
        this.state.fromDate,
        this.state.toDate,
        isCumulative ? "total" : "current"
      );
      this.setState({
        indicatorData: data.indicators,
        iconColors: data.iconColors,
        parsedReportingPeriods: true
      });
    }
  }

  downloadExcel(e, summary_cumulation_period = null) {
    e.preventDefault();
    let { companyId } = this.props;
    let { fromDate, toDate } = this.state;
    fromDate = fromDate.format("YYYY-MM-DD");
    toDate = toDate
      .clone()
      .add(1, "days")
      .format("YYYY-MM-DD");
    let fetch = new Traec.Fetch("company_report", "list", {
      companyId,
      fromDate,
      toDate,
      format: "excel",
      summary_cumulation_period
    });

    Swal.queue([
      {
        title: "Downloading",
        confirmButtonText: "Generate Excel Report",
        html:
          "<p>Pulling dashboard data into an Excel report.</p> <p>This involved aggregating a large amout of data and may take several minutes depending on the size of your project, reporting packages, and reported values.</p><p>Please be patient.</p>",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return fetch
            .rawFetch()
            .then(response => response.blob())
            .then(data => {
              let blobUrl = window.URL.createObjectURL(data);
              Swal.insertQueueStep({
                showConfirmButton: false,
                showCancelButton: false,
                title: "Download ready",
                html: `<p>Click here to download</p><a class="btn btn-primary" href="${blobUrl}" download="company_report.xlsx">Download</a>`
              });
            })
            .catch(err => {
              Swal.insertQueueStep({
                type: "error",
                title: "Error",
                text: "There was an error generating your report.  Please contact support if the problem persists."
              });
            });
        }
      }
    ]);
  }

  /**********************
   RENDER METHODS
   **********************/

  setSelectedSustainabilityIssue(issue) {
    this.setState({ selectedSustainabilityIssue: issue });
  }

  setSelectedIndicator(indicator) {
    this.setState({ selectedIndicators: indicator });
  }

  render() {
    let { companyId, company, companyTargets, companyIndicators, isSupplierHoldingCompany, iconPath, indicatorOrder } = this.props;
    let { showColorScale, modalTitle, fromDate, toDate, reportFilters, cumulation } = this.state;

    if (!company) {
      return loading("dashboard");
    }

    // Redirects if required
    if (this.state.rssbReport) {
      return <Redirect to={`/company/${this.props.companyId}/report/`} />;
    }
    // Redirect to the map based on meta_json field
    if (company.getInPath("meta_json.dashboard") === "map_uk") {
      return <Redirect to={`/company/${companyId}/map/`} />;
    }

    let modalId = `dropdown-${companyId}`;
    let ModalContent = (modalTitle || "").includes("form") ? CompanyFormJoin : CompanyExportZipForm;

    return (
      <CompanyPermission
        companyId={companyId}
        requiresAdmin={false}
        requiredActions={["READ_COMPANY_REPORT"]}
        showWarning={true}
        warning={<CompanyWarningMessage />}
      >
        {/*<h3>Corporate Dashboard - Overview</h3>*/}

        <CompanyDateRangePicker
          company={company}
          onChange={this.setDateRangeValue}
          fromDate={fromDate}
          toDate={toDate}
        />

        <ExportDropdown 
          {...this.props} 
          modalId={modalId} 
          setState={this.setState} 
          showColorScale={showColorScale}
        />
        <div style={{ clear: "both" }} />

        <CompanyColorScale show={showColorScale} />

        <BSModal
          id={modalId}
          title={modalTitle || "Dynamic Company Modal"}
          body={<ModalContent companyId={companyId} />}
        />

        <div className="row justify-content-between mt-md-3">
            {/* <div className="col-sm-4">
            <ErrorBoundary>
              <ReportCount companyId={companyId} />
            </ErrorBoundary>
            </div> */}

            <div className="col-sm col-md-6 col-lg-4 col-xl-3 mb-sm-3">
              <ErrorBoundary>
                <IndicatorsDoughnut companyId={companyId} />
              </ErrorBoundary>
            </div>
            <div className="col-sm col-md-6 col-lg-4 col-xl-3 mb-sm-3">
              <ErrorBoundary>
                <Link
                  style={{ textDecoration: "none", color: "inherit", cursor: "pointer" }}
                  to={`/company/${companyId.substring(0, 8)}/health`}
                >
                  <ReportsApproved companyId={companyId} footer={<p className="btn-link"> Click for analysis</p>} />
                </Link>
              </ErrorBoundary>
            </div>
            <div className="col-sm col-md-12 col-lg-4 col-xl-6 mb-sm-3">
              <ErrorBoundary>
                <Link
                  style={{ textDecoration: "none", color: "inherit", cursor: "pointer" }}
                  to={`/company/${companyId.substring(0, 8)}/health`}
                >
                  <ReportSubmittedLine companyId={companyId} />
                </Link>
              </ErrorBoundary>
            </div>
          </div>

        <IndicatorWarning indicators={companyIndicators} />

        {/* Render the RAG icons */}
        <ErrorBoundary>
          <DashboardComponents
            companyId={companyId}
            iconPath={iconPath}
            indicators={companyIndicators}
            targets={companyTargets}
            indicatorOrder={indicatorOrder}
            downloadExcel={this.downloadExcel}
          />
        </ErrorBoundary>

        {isSupplierHoldingCompany ? (
          <>
            <div className="mb-3">
              <span className="h5">Analysis of full reporting data set</span>
              <TitleTooltip
                text="Use this Microsoft Power BI plugin to explore the data retrieved from the full reporting.
                This corresponds to information uploaded by respondents who were able to provide a log of the
                characteristics of each of their employees. The data provided was anonymous.
                Use the interactive features of this plugin to filter the data and change the pages to
                look at different diversity categories."
              />
            </div>

            <div className="embed-responsive embed-responsive-16by9">
              <iframe
                title="Action_Sustainability 2024 PRODUCTION POWERBI"
                width="600"
                height="373.5"
                src="https://app.powerbi.com/view?r=eyJrIjoiMmRlZmViOTEtY2E5Yi00YzcwLWIzMDUtY2NkODEzYzI0OTViIiwidCI6Ijg1OGZjOTlhLTFlNzktNGNiOS04NDEwLWUyODkwZDdkZWNiYiJ9 "
                frameborder="0"
                allowFullScreen="true"
              />
            </div>
          </>
        ) : null}
      </CompanyPermission>
    );
  }
}

const TitleTooltip = props => {
  let { text } = props;

  if (!text) {
    return null;
  }
  return (
    <Tooltip animateFill={false} html={<div className="text-left">{text}</div>}>
      <Octicon name="info" className="ml-2" />
    </Tooltip>
  );
};

const mapStateToProps = (state, ownProps) => {
  //let { companyId } = ownProps.match.params;
  const { companyId } = Traec.utils.getFullIds(state, ownProps.match.params);

  let company = state.getInPath(`entities.companies.byId.${companyId}`);
  let companyList = state.getInPath("entities.companies.byId");
  let userPermissions = getCompanyPermissions(state, companyId);
  let isTenantAdmin = state.getInPath("auth.user.is_tenant_superuser");

  // Get the current company indicators
  let companyIndicators = state.getInPath(`entities.companyObjects.byId.${companyId}.indicators`);

  // Get the baseMetrics
  let baseMetrics = state.getInPath("entities.baseMetrics.byId");

  // Get the company targets
  let companyTargets = state.getInPath(`entities.companyObjects.byId.${companyId}.targets`);

  //Download file
  let downloadUrl = state.getInPath(`entities.download.byId.${companyId}`);

  // Are we using a cumulative or latest dashboard data
  let isCumulative = state.getInPath(`ui.dashboards.${companyId}.cumulativeValues`);
  isCumulative = isCumulative === undefined ? true : isCumulative;

  // Get projects under this company
  let projects = state.getInPath(`entities.companies.byId.${companyId}.projects`) || Traec.Im.Map();
  let projectId = projects.toList().map(project => {
    let projectName = project.getInPath(`name`);
    let projectUid = project.getInPath(`uid`);
    return { projectName, projectUid };
  });

  // Is this company the holding company
  let supplierHoldingCompanyId = state.getInPath("entities.tenant.meta_json.supplier_holding_company_id");
  let isSupplierHoldingCompany = (supplierHoldingCompanyId && companyId?.startsWith(supplierHoldingCompanyId)) || false;
  if (isSupplierHoldingCompany) {
    console.log("This business unit is the holding company for all suppliers", companyId, supplierHoldingCompanyId);
  }

  // Get the order of the indicators saved in tenant meta_json
  let indicatorOrder = state.getInPath("entities.tenant.meta_json.indicator_order");
    
  let iconPath = state.getInPath(`ui.styles.iconPath`);

  return {
    companyId,
    projectId,
    company,
    companyList,
    companyIndicators,
    baseMetrics,
    companyTargets,
    downloadUrl,
    isCumulative,
    isSupplierHoldingCompany,
    userPermissions,
    isTenantAdmin,
    iconPath,
    indicatorOrder
  };
};

export default connect(mapStateToProps)(CompanyHome);
