import React from "react";

export function CompanyWarningMessage() {
  return (
    <div className="alert alert-warning text-center">
      <strong>
        The Industry Dashboard is a paid for feature of the Diversity Survey. It contains supplementary data points such
        as sector, size, and location. If you would like to access the Industry Dashboard please contact{" "}
        <a href="mailto:liv.banfield@actionsustainability.com" target="_blank">
          liv.banfield@actionsustainability.com
        </a>
      </strong>
    </div>
  );
}
